import { faGit, faNode, faReact } from "@fortawesome/free-brands-svg-icons";
import Header from "./Header";
import React, { useState, useRef, useEffect } from "react";
import { useSprings, useSpring, animated, easings, to } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colors from "../colors";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";

export default function Hero() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
        background: colors.black,
        cursor: "pointer",
      }}
    >
      <Header />
      <Slider />
    </div>
  );
}

const initialCardsData = [
  {
    id: 1,
    link: "https://norahvalentemusic.com",
    imgPath: "norah",
    techIcons: [faReact, faNode],
    repo: "https://gitlab.com/luizfrm/norah",
  },
  {
    id: 2,
    link: "https://cardiologist3d.vercel.app",
    imgPath: "cardiologist",
    techIcons: [faReact],
    repo: "https://gitlab.com/luizfrm/cardiologist3d",
  },
  {
    id: 3,
    link: "https://poolcleaner.vercel.app/",
    imgPath: "poolCleaner",
    techIcons: [faReact],
    repo: "https://gitlab.com/luizfrm/poolcleaner",
  },
  {
    id: 4,
    link: "https://tattoo-topaz-delta.vercel.app/",
    imgPath: "test",
    techIcons: [faReact],
    repo: "https://gitlab.com/luizfrm/tattoo",
  },
];

const Slider = () => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [offset, setOffset] = useState(0);

  const initialY = (index) => (isMobile ? 15 - 15 * index : 15 - 15 * index);
  const initialZ = (index) => 15 * (initialCardsData.length - index - 1);

  const [cards] = useState(initialCardsData);

  const [springs, api] = useSprings(cards.length, (index) => ({
    y: initialY(index),
    z: initialZ(index),
    immediate: true,
  }));

  const handleClick = async () => {
    if (isAnimating) return;
    setIsAnimating(true);

    const topIndex = offset % cards.length;

    api.start((index) => {
      if (index === topIndex) {
        return {
          y: initialY(0) + 100,
          config: { duration: 750, easing: easings.easeOutCubic },
        };
      }
    });

    const timeOut = setTimeout(() => {
      setOffset((prevOffset) => {
        if (prevOffset === 3) return 0;
        return prevOffset + 1;
      });

      api.start((index) => {
        const newIndex = (index - offset - 1 + cards.length) % cards.length;
        return {
          y: initialY(newIndex),
          z: initialZ(newIndex),
          immediate: false,
          config: { duration: 750, easing: easings.easeOutCubic },
        };
      });

      setIsAnimating(false);
    }, 750);
  };

  return (
    <div onClick={handleClick}>
      <div
        className="slider"
        style={{
          position: "relative",
          top: "15vh",
          width: "100vw",
          height: "100vh",
          // overflow: "hidden",
          perspective: "200px",
          perspectiveOrigin: "50% 100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "space-between",
        }}
      >
        {cards.map((card, i) => {
          const index = (i - offset + cards.length) % cards.length;
          return (
            <Card
              isFirst={index === 0}
              repo={card.repo}
              key={card.id}
              link={card.link}
              imgPath={card.imgPath}
              style={{
                zIndex: cards.length - index,
                transform: to(
                  [springs[i].y, springs[i].z],
                  (yVal, zVal) =>
                    `translate3d(-50%, -50%, ${zVal}px) translateY(${yVal}%)`
                ),
              }}
              techIcons={card.techIcons}
            />
          );
        })}
      </div>
    </div>
  );
};

const Card = ({ link, repo, style, techIcons, isFirst }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    if (isFirst) iframeRef.current.src += "";
  }, [isFirst]);

  return (
    <animated.div
      style={{
        ...style,
        position: "absolute",
        bottom: "-11%",
        left: "50%",
        height: "62vh",
        display: "flex",
        justifyContent: "space-between",
        overflow: "hidden",
        borderRadius: 10,
        cursor: "pointer",
        userSelect: "none",
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        msUserSelect: "none",
        boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.25)",
      }}
      onClick={() => {}}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "10%",
          backgroundColor: "transparent",
          zIndex: 2,
          pointerEvents: "auto",
          display: "flex",
        }}
      >
        <FontAwesomeIcon
          icon={faLink}
          color={colors.blue}
          style={{
            padding: 8,
            backgroundColor: colors.darkBlue,
            borderRadius: "8px 0px 0px 0px",
          }}
          onClick={() => window.open(link, "_blank", "noopener,noreferrer")}
          size="xl"
        />
        <FontAwesomeIcon
          icon={faGit}
          color={colors.blue}
          style={{
            padding: 8,
            backgroundColor: colors.darkBlue,
          }}
          onClick={() => window.open(repo, "_blank", "noopener,noreferrer")}
          size="xl"
        />
        {techIcons?.map((icon, index) => (
          <FontAwesomeIcon
            icon={icon}
            color={colors.blue}
            style={{
              padding: 8,
              backgroundColor: colors.darkBlue,
              borderRadius: index === 0 ? "0px" : "0px 0px 8px 0px",
            }}
            size="xl"
          />
        ))}
      </div>

      <div style={{ backgroundColor: colors.darkBlue }}>
        <iframe
          ref={iframeRef}
          src={link}
          title="Embedded Website"
          style={{
            width: isMobile ? "80vw" : "70vw",
            height: "100%",
            border: "none",
            borderRadius: 10,
            zIndex: 1,
          }}
        />
      </div>
    </animated.div>
  );
};
