import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faGitlab,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { isMobile } from "react-device-detect";

import colors from "../colors";

export default function Header() {
  return (
    <header
      style={{
        display: "flex",
        justifyContent: "space-between",
        margin: 32,
        fontSize: 12,
        left: 0,
        right: 0,
        position: "absolute",
        alignItems: "center",
        cursor: "auto",
      }}
    >
      {!isMobile && (
        <div style={{ flex: 1 }}>
          <h1>Luiz Felipe Rodrigues</h1>
        </div>
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        <SocialMedia icon={faGitlab} link="https://gitlab.com/luizfrm" />
        <SocialMedia
          icon={faInstagram}
          link="https://www.instagram.com/luizfelipe_rm/"
        />
        <SocialMedia
          icon={faLinkedin}
          link="https://www.linkedin.com/in/luiz-felipe-rodrigues-menezes-9517591a5/"
        />
      </div>

      <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
        <ContactButton />
      </div>
    </header>
  );
}

const SocialMedia = ({ link, icon }) => {
  return (
    <a
      style={{
        color: colors.blue,
        marginLeft: 20,
      }}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={icon} size="3x" />
    </a>
  );
};

const ContactButton = () => {
  return (
    <a
      href="https://wa.me/5521970843754"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        color: colors.blue,
        padding: "8px 16px",
        backgroundColor: colors.blue,
        color: colors.darkBlue,
        // border: `1px solid ${colors.blue}`,
        textDecoration: "none",
        fontSize: 20,
        borderRadius: 8,
        display: "flex",
        flexDirection: "center",
        alignItems: "center",
      }}
    >
      <b>contato </b>
      <FontAwesomeIcon
        icon={faWhatsapp}
        style={{ marginLeft: 8, fontSize: 24 }}
      />
    </a>
  );
};
